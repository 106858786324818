//  FONT
$textFontFamily: 'Poppins', sans-serif;

//  COLORS
/* GENERAL */
* {
  --primaryColor: #004E71;
  --secondaryColor: white;
  --emphasis: #EA4379;
  --interactive: #004E71;
  --selectedText: black;
}

$backgroundColor: #EEEEEE;
$fontColor: black;
$borderColor: rgba(0, 0, 0, 0.3);

$primaryColor: var(--primaryColor);
$primaryTitle: white;
$primaryIcon: #eeeeee;

$secondaryColor: var(--secondaryColor);
$secondaryTitle: black;
$secondaryIcon: #004E71;

$emphasis: var(--emphasis);
$interactive: var(--interactive);
$selectedText: var(--selectedText);

$alert: red;

$cancel: #cc3333;
$accept: #33cc33;
$progress: #6CC4C7;
$warning: #F9B100;

/* CARDS */
$backgroundCards: white;
$fontCards: black;

/* TABS */
$backgroundTabs: white;
$fontTabs: black;

/* INPUTS */
$backgroundInput: white;
$fontInput: black;
$borderInput: black;
$placeholderInput: rgba($fontInput, 0.7);

/* BUTTONS */
$backgroundButton: white;
$fontButton: black;
$borderButton: $interactive;
$hoverButton: white;

@mixin backgroundenfasis($percentage) {
  background-color: rgba($emphasis, $percentage);
}